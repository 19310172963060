import _ from 'lodash';
import sumsumApi from '@/api/SumsumApi';

const state = {
  dailyRoutines: [],
  selectedDailyRoutine: null,
  showDailyRoutineViewer: false,
};

const getters = {
};

const actions = {
  async initDailyRoutine({ commit }) {
    const dailyRoutines = await sumsumApi.getDailyRoutines();
    commit('setDailyRoutines', dailyRoutines);
    commit('setSelectedDailyRoutine', dailyRoutines.length ? dailyRoutines[0] : null);
  },
  async dismissDailyRoutine({ commit, state }) {
    const updateDrs = _.reject(state.dailyRoutines, dr => dr.entityId === state.selectedDailyRoutine.entityId);
    commit('setDailyRoutines', updateDrs);
    commit('setSelectedDailyRoutine', updateDrs[0]);
  },
};

const mutations = {
  setDailyRoutines(state, dailyRoutines) {
    state.dailyRoutines = dailyRoutines;
  },
  setSelectedDailyRoutine(state, dailyRoutine) {
    state.selectedDailyRoutine = dailyRoutine;
  },
  setShowDailyRoutineViewer(state, showDailyRoutineViewer) {
    state.showDailyRoutineViewer = showDailyRoutineViewer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

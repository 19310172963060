<template>
  <div class="daily-routine-viewer" v-if="showDailyRoutineViewer">
    <div class="page-wrapper">
      <div class="content" v-if="dailyRoutines.length">
      <div class="page-content" ref="pageContent">
        <div class="close-action" v-on:click="close">
          <img :src="require(`@/assets/icons/close-white.svg`)"/>
        </div>
        <page-viewer
            v-for="(dailyRoutine, index) in dailyRoutinesToDisplay"
            :key="index"
            class="daily-routine-item"
            ref="dailyRoutineItems"
            content-type="daily-routine"
            :ratio="dailyRoutine.page.ratio"
            :pageId="index"
            :page-content="dailyRoutine.page.content"
            :style="getStyle(index)">
          <template v-slot:footer>
            <div v-show="index === 0" class="buttons flex-row">
              <riseup-button
                  :title="firstAction.text"
                  :action="() => handleAction(firstAction.action)"
                  variant="secondary"
                  class="action-button"/>
              <div class="remaining">נשארו עוד {{dailyRoutines.length}}</div>
              <riseup-button
                  v-if="secondAction"
                  :title="secondAction.text"
                  variant="secondary"
                  :action="() => handleAction(secondAction.action)"
                  class="action-button"/>
            </div>
          </template>
        </page-viewer>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import storyLib from '@riseupil/story-lib';
import { mapActions, mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import ChangeTransactionCategoryPopup
  // eslint-disable-next-line max-len
  from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategoryPopup.vue';

export default {
  name: 'DailyRoutineViewer',
  components: {
    PageViewer: storyLib.PageViewer,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      currentPageIndex: 0,
    };
  },
  computed: {
    ...mapState('dailyRoutines', ['selectedDailyRoutine', 'dailyRoutines', 'showDailyRoutineViewer']),
    firstAction() {
      return this.selectedDailyRoutine.actions[0];
    },
    secondAction() {
      return this.selectedDailyRoutine.actions.length > 1 ? this.selectedDailyRoutine.actions[1] : null;
    },
    dailyRoutinesToDisplay() {
      return this.dailyRoutines.slice(0, 4);
    },
  },
  methods: {
    ...mapActions('dailyRoutines', ['dismissDailyRoutine']),
    ...mapMutations('dailyRoutines', ['setShowDailyRoutineViewer']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editTransaction', ['startEditingTransaction']),
    getDailyRoutineTypeToActionPopupMap() {
      return {
        CATEGORY_EXCEEDED: () => {},
        CATEGORY_ABOUT_TO_EXCEED: () => {},
        NEW_TRANSACTION_CATEGORIZATION: async () => {},
        MIGHT_BE_FIXED: async () => {
          const transaction = {
            ...this.selectedDailyRoutine.transaction,
            [this.selectedDailyRoutine.transaction.isIncome ? 'incomeAmount' : 'billingAmount']: this.selectedDailyRoutine.transaction.amount,
          };
          await this.startEditingTransaction({
            transaction,
            categoryName: transaction.categoryName,
            categoryId: transaction.categoryId,
          });
          await this.openModal({ component: ChangeTransactionCategoryPopup });
        },
      };
    },

    close() {
      this.setShowDailyRoutineViewer(false);
    },

    async next() {
      await this.dismissDailyRoutine();
    },

    async handleAction(type) {
      if (type === 'dismiss') {
        await this.next();
      } else {
        await this.showActionPopup();
        // await this.next();
        // this.close();
      }
    },

    async showActionPopup() {
      const mapper = this.getDailyRoutineTypeToActionPopupMap();
      await mapper[this.selectedDailyRoutine.type]();
    },

    getStyle(index) {
      if (index === 0) {
        return {
          top: '0',
          transform: 'scale(0.8)',
          zIndex: this.dailyRoutines.length - index,
        };
      }

      const scale = 0.8 ** (index + 1);

      let top = 0;
      let increment = 2;

      for (let i = 1; i <= index; i++) {
        top += increment;
        increment *= 0.8;
      }

      return {
        position: 'absolute',
        transform: `scale(${scale}, ${0.8})`,
        width: '100%',
        left: 0,
        top: `-${top}%`,
        zIndex: this.dailyRoutines.length - index,
        transition: 'top 0.5s ease-in-out, width 0.5s ease-in-out',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.daily-routine-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;

  .page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  @media (min-width: 430px) {
    .page-wrapper {
      width: 400px;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #000000b3;

    .close-action {
     position: absolute;
      top: 30px;
      right: 30px;
      z-index: 100;
    }

    .buttons {
      height: 100px;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      margin: 0 5px;

      .action-button {
        color: $riseup_white;
        width: 100px;
      }

      .remaining {
        color: $riseup_white;
      }
    }

    .page-content {
      flex-grow: 1;
      position: relative;
    }
  }

  .daily-routine-item {
    border-radius: 24px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
}
</style>
